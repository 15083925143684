<template>
  <div class="container col-lg-10 col-md-12 col-sm-12 my-5">
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <!--<b-alert show variant="danger">
      <h4 class="alert-heading">¡Sistema en mantenimiento!</h4>
      <hr />
      <b class="mb-0">
        Se están realizando cambios, el sistema se habilitara a las 10 a.m.
      </b> </b-alert
    >-->
    <!---->

    <b-alert show dismissible variant="success" class="mb-0">
      <h4 class="alert-heading">Cuentas para realizar el pago ⬇</h4>
      <p class="m-0 mb-1">
        <em>INDICAR QUE ES CUENTA UNSA - CATEGORIA: PAGO VARIOS</em>
      </p>
      <p class="m-0"><b> Cuenta BCP: </b>215-1588792-0-19</p>
      <p class="m-0"><b> Cuenta BANCO DE LA NACIÓN: </b>101-202992</p>
      <p class="m-0 mt-1">* Debes Indicar el Nombre del Rubro: Varios.</p>
      <p class="m-0 mt-1">* Si te solicitan código es el número de su DNI.</p>
      <p class="m-0">
        * Por aplicativo BCP la empresa se llama Universidad Nacional de San
        Agustín y se van a pago varios.
      </p>
      <hr class="my-2" />
      <b class="m-0">
        Si es tu primera vez deberás registrarte en el sistema con el botón
        "Registrarse".
      </b>
    </b-alert>
    <div>
      <b-card no-body>
        <b-row no-gutters>
          <b-col md="5">
            <b-card-img
              src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/logo ucps.png?alt=media"
              alt="Image"
              class="rounded-0 imgCentro"
            ></b-card-img>
          </b-col>
          <b-col md="7">
            <div card>
              <div class="card-header">
                <h1 class="text-center">Inicio de Sesión</h1>
              </div>
              <div class="card-body">
                <AlertError ref="error" />
                <b-form v-if="show">
                  <b-form-group
                    id="input-group-1"
                    label="Correo electrónico:"
                    label-for="input-1"
                    description="Ingrese el correo usado para el registro."
                    class="my-2"
                  >
                    <b-form-input
                      :state="validEmail(form.usuario)"
                      autofocus="autofocus"
                      id="input-1"
                      v-model="form.usuario"
                      type="text"
                      placeholder="Ingrese correo"
                      required
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label="Contraseña:"
                    description="Ingrese al menos 1 letra, al menos 1 número, al menos 1 letra mayúscula, al menos 6 caracteres, no se permite espacios."
                    class="my-2"
                  >
                    <b-input-group>
                      <b-form-input
                        :state="
                          form.contraseña != '' && form.contraseña.length > 5
                        "
                        @keyup.enter="login"
                        id="input-2"
                        v-model="form.contraseña"
                        :type="seeContra"
                        placeholder="Ingrese contraseña"
                        required
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-checkbox
                          button
                          button-variant="tranparent"
                          v-model="seeContra"
                          value="text"
                          unchecked-value="password"
                          ><b-icon-eye-slash v-if="seeContra == 'password'" />
                          <b-icon-eye v-else />
                        </b-form-checkbox>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <div class="text-center">
                    <b-button
                      type="button"
                      @click="login"
                      class="my-3 w-100"
                      style="background-color: rgb(119, 35, 45); border: none"
                      >Ingresar</b-button
                    >
                    <button
                      type="button"
                      @click="cambio"
                      class="btn btn-secondary w-100"
                    >
                      Registrarse
                    </button>
                    <p class="mb-0 mt-1">
                      <small
                        style="color: #1775ce; cursor: pointer"
                        @click="openModal"
                        ><u>Recuperar contraseña</u></small
                      >
                    </p>
                    <p class="mb-0">
                      <small
                        >Si tiene algún problema contactarse al correo de
                        soporte:</small
                      >
                    </p>
                    <p class="mb-0">
                      <small><b>soporte_virtual@ucps.unsa.pe</b></small>
                    </p>
                  </div>
                </b-form>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-alert show variant="danger" class="mb-0">
      <p class="m-0 mb-1">
        <b>*</b> El ingreso es con <b>su cuenta personal</b> @gmail.com, @hotmail, etc y no con su
        cuenta institucional UCPS.
      </p>
      <p class="m-0 mb-1">
        <b>*</b> Si se olvidan o quieren cambiar de contraseña lo podrán hacer dirigiéndonos a la opción de <b>Recuperar contraseña</b>.
      </p>
    </b-alert>
    <!---->
    <!--Recuperar contraseña-->
    <div>
      <b-modal
        scrollable
        centered
        v-model="showContra"
        title="Recuperar contraseña"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <form>
            <AlertError ref="errorModal" />
            <div class="container">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1"
                      ><b>Correo electrónico:</b></label
                    >
                    <b-form-group description="Correo asociado a su cuenta.">
                      <b-form-input
                        type="text"
                        class="form-control"
                        placeholder="Ingrese correo electrónico"
                        v-model="formContra.username"
                        :state="validEmail(formContra.username)"
                        required
                      />
                    </b-form-group>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1"><b>DNI:</b></label>
                    <b-form-input
                      type="number"
                      class="form-control"
                      placeholder="Ingrese DNI"
                      v-model="formContra.dni"
                      :state="
                        formContra.dni != null && formContra.dni.length == 8
                      "
                      required
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1"
                      ><b>Nueva contraseña:</b></label
                    >
                    <b-form-group
                      description="Ingrese al menos 1 letra, al menos 1 número, al menos 1 letra mayúscula, al menos 6 caracteres, no se permite espacios."
                    >
                      <b-input-group
                        ><b-form-input
                          :type="seeContra2"
                          class="form-control"
                          placeholder="Ingrese contraseña"
                          v-model="formContra.password"
                          :state="validContra(formContra.password)"
                          required
                        />
                        <b-input-group-append>
                          <b-form-checkbox
                            button
                            button-variant="tranparent"
                            v-model="seeContra2"
                            value="text"
                            unchecked-value="password"
                            class="mr-n2 mb-n1"
                            ><b-icon-eye-slash
                              v-if="seeContra2 == 'password'"
                            />
                            <b-icon-eye v-else />
                          </b-form-checkbox>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <div class="form-group">
                    <label for="exampleInputEmail1"
                      ><b>Verificar Contraseña:</b></label
                    >
                    <b-input-group
                      ><b-form-input
                        :type="seeContra2verify"
                        class="form-control"
                        placeholder="Verifique contraseña"
                        v-model="formContra.confirmation"
                        required
                      />
                      <b-input-group-append>
                        <b-form-checkbox
                          button
                          button-variant="tranparent"
                          v-model="seeContra2verify"
                          value="text"
                          unchecked-value="password"
                          class="mr-n2 mb-n1"
                          ><b-icon-eye-slash
                            v-if="seeContra2verify == 'password'"
                          />
                          <b-icon-eye v-else />
                        </b-form-checkbox>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="restore"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
    <!--Comunicado-->
    <div v-if="false">
      <b-modal
        body-bg-variant="white"
        centered
        v-model="showMessa"
        hide-footer
        hide-header
      >
        <b-avatar
          button
          variant="light"
          title="Cerrar"
          icon="x"
          class="closeModal"
          @click="closeModal"
          style="z-index: 1100"
        ></b-avatar>
        <b-alert show variant="danger" class="mb-0">
          <h4 class="alert-heading"><b>COMUNICADO ACADÉMICO</b></h4>
          <br>
          <p class="m-0 mb-1">
            <em><b>!Ya <u>no hay vacantes</u> para el programa de Seguridad Industrial y Minera lunes a viernes turno noche¡</b></em>
          </p>
          <!--<p class="m-0 mb-1">
            Buenos días estimados alumnos de la UCPS, para comunicarles a todos
            nuestros alumnos matriculados 2022
            <b
              >QUE PARA EL MES DE JULIO INGRESARÁN A SUS CLASES CON SU CORREO
              INSTITUCIONAL</b
            >
            donde se les enviará la invitación para unirse a sus cursos y así
            puedan ingresar a sus clases en vivo por meet para el inicio del
            módulo respectivo.
          </p>
          <p class="m-0 mb-1">
            <b
              >Credenciales para el ingreso a su correo institucional de
              Google:</b
            >
          </p>
          <p class="m-0 mb-1">
            <b>Correo:</b> dni<b-link>@ucps.edu.pe</b-link>
          </p>
          <p class="m-0 mb-1"><b>Contraseña:</b> dni</p>
          <p class="m-0 mb-1">• Por seguridad cambiar su contraseña</p>
          <p class="m-0 mb-1">• Colocar su imagen de perfil</p>
          <p class="m-0 mb-1 mt-2">
            <b>Consultas respectivas al correo</b>
          </p>
          <p class="m-0 mb-1">
            <em>Soporte_virtual@ucps.unsa.pe</em>
          </p>-->
        </b-alert>
        <!--
        <center>
          <b-img
            fluid
            src="https://firebasestorage.googleapis.com/v0/b/ucps-7d113.appspot.com/o/Comunicado.jpeg?alt=media"
          ></b-img>
        </center>-->
      </b-modal>
    </div>
    <!---->
  </div>
</template>

<script>
import AlertError from "../components/AlertError.vue";
export default {
  components: {
    AlertError,
  },
  data() {
    return {
      seeContra: "password",
      seeContra2: "password",
      seeContra2verify: "password",
      showContra: false,
      showMessa: false,
      selected: "",
      form: {
        email: "",
        name: "",
        food: null,
        checked: [],
        usuario: "",
        contraseña: "",
      },
      formContra: {},
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      show: true,
    };
  },
  created() {
    this.showMessa = true;
  },
  methods: {
    closeModal() {
      this.showMessa = false;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validContra: function (contra) {
      var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\w{6,}$/;
      return re.test(contra);
    },
    openModal() {
      this.showContra = true;
      this.formContra = {};
    },
    exit() {
      this.showContra = false;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    cambio() {
      this.$router.push("register");
    },
    Ingreso() {
      //this.$router.push("dashboard");
      //Utilizar url validaaa
      //window.location.href = "https://sistema-ucps.herokuapp.com/users";
      window.location.href = window.location.origin;
    },
    async login() {
      //correo
      if (!this.validEmail(this.form.usuario)) {
        this.$refs.error.showAlert("Correo invalido");
        return;
      }
      this.selected = "circular";
      //console.log(this.editedItem.status);
      await this.$axios
        .post("/auth/login", {
          username: this.form.usuario,
          password: this.form.contraseña,
        })
        .then((response) => {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("authenticated", true);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem(
            "authorities",
            JSON.stringify(response.data.authorities)
          );
          localStorage.setItem("imageUrl", response.data.imageUrl);
          localStorage.setItem("checkPhone", response.data.checkPhone);
          this.$message.success("MUY BIEN HA INGRESO EXITOSAMENTE");
          this.Ingreso();
        })
        .catch((error) => {
          this.selected = "";
          //capturando datos de respuesta
          try {
            this.$refs.error.showAlert(error.response.data.content);
          } catch (error) {
            console.log(error);
          }
        });
    },
    async restore() {
      //datos
      if (
        this.formContra.dni == null ||
        this.formContra.dni.length != 8 ||
        this.formContra.confirmation == null
      ) {
        this.$refs.errorModal.showAlert("Llenar datos");
        return;
      }
      //correo
      if (!this.validEmail(this.formContra.username)) {
        this.$refs.errorModal.showAlert("Correo invalido");
        return;
      }
      //contraseña
      if (!this.validContra(this.formContra.password)) {
        this.$refs.errorModal.showAlert("Contraseña invalida");
        return;
      }
      this.formContra.dateBirth = "";
      this.selected = "circular";
      this.showContra = false;
      //console.log(this.editedItem.status);
      await this.$axios
        .post("/auth/recovery-password", this.formContra)
        .then((res) => {
          this.selected = "";
          this.$message.success(res.data.content);
        })
        .catch((error) => {
          this.selected = "";
          console.log(error);
          this.$message.error(error.response.data.content);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: #1d425e;
  color: white;
}
</style>
<style scoped>
.imgCentro {
  display: block;
  margin: auto;
  width: 95%;
  height: 95%;
  object-fit: cover;
}
.closeModal {
  position: absolute;
  top: 25px;
  right: 30px;
}
</style>
